import React, { useEffect, useState } from 'react'
import OptionsMenu from 'src/@core/components/option-menu'
import { useRouter } from 'next/router'

const languageOptions = [
  { code: 'en', text: 'English', abbreviation: 'EN' },
  { code: 'es', text: 'Español', abbreviation: 'ES' },
  { code: 'pt', text: 'Português', abbreviation: 'PT' },
  { code: 'id', text: 'Indonesian', abbreviation: 'ID' }
]

const LanguageDropdown = ({ settings, saveSettings }) => {
  const router = useRouter()
  const { layout } = settings

  const getCurrentLanguageAbbreviation = languageCode => {
    return languageOptions.find(lang => lang.code === languageCode)?.abbreviation || 'EN'
  }

  const [currentLanguageAbbreviation, setCurrentLanguageAbbreviation] = useState(
    getCurrentLanguageAbbreviation(router.locale)
  )

  useEffect(() => {
    setCurrentLanguageAbbreviation(getCurrentLanguageAbbreviation(router.locale))
  }, [router.locale])

  const handleLangItemClick = lang => {
    let newUrl
    if (lang === 'en') {
      newUrl = `${process.env.NEXT_PUBLIC_HTTP}${process.env.NEXT_PUBLIC_HOST}${router.asPath}`
    } else {
      newUrl = `${process.env.NEXT_PUBLIC_HTTP}${lang}.${process.env.NEXT_PUBLIC_HOST}${router.asPath}`
    }

    if (typeof window !== 'undefined') {
      window.location.assign(newUrl)
      localStorage.setItem('userChangedLanguage', 'true')
    }

    saveSettings({ ...settings, direction: 'ltr' })
  }

  return (
    <OptionsMenu
      icon={<span>{currentLanguageAbbreviation}</span>}
      menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
      options={languageOptions.map(lang => ({
        text: lang.text,
        menuItemProps: {
          sx: { py: 2 },
          selected: router.locale === lang.code,
          onClick: () => handleLangItemClick(lang.code)
        }
      }))}
    />
  )
}

export default LanguageDropdown

import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout'

import Image from 'next/image'

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideHorizontalNavItems from './components/horizontal/ServerSideNavItems'
import VerticalAppBarContent from './components/vertical/AppBarContent'
import AppBarContent from './components/vertical/AppBarContent'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import HorizontalNavItems from '../@core/layouts/components/horizontal/navigation/HorizontalNavItems'
import { Icon } from '@iconify/react'
import React from 'react'
import Link from 'next/link'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit'
})

const AppBrand = () => {
  const logoUrl = `${process.env.NEXT_PUBLIC_LOGO}`

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <StyledLink href='/' passHref>
        <Image src={logoUrl} alt='logo' width={50} height={50} />
        <Typography variant='h6' sx={{ ml: 2, textAlign: 'center', marginLeft: '20px' }}>
          Calculate <br /> Input
        </Typography>
      </StyledLink>
    </Box>
  )
}

const UserLayout = ({ children, contentHeightFixed, menuItems }) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()

  // const {menuItems: verticalMenuItems} = ServerSideVerticalNavItems()

  /*const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }
*/

  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
  settings.layout = hidden ? 'vertical' : settings.layout

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          branding: () => <AppBrand />,

          // navItems: verticalMenuItems,
          navItems: menuItems,
          lockedIcon: <Icon icon='mdi:arrow-left-bold-circle-outline' />,
          unlockedIcon: <Icon icon='mdi:arrow-right-bold-circle-outline' />
        },
        appBar: {
          content: props => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
      {...(settings.layout === 'horizontal' && {
        horizontalLayoutProps: {
          navMenu: {
            navItems: HorizontalNavItems()
          },
          appBar: {
            content: () => <AppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings} />
          }
        }
      })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout

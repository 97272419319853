// ** Next Imports
import Head from 'next/head'
import { useRouter } from 'next/router'
import { GoogleTagManager } from '@next/third-parties/google'

import { CacheProvider } from '@emotion/react'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/iconify-bundle/icons-bundle-react'

// ** Global css styles
import '../../styles/globals.css'
import UserLayout from '../layouts/UserLayout'
import { BreadcrumbsProvider } from '../configs/BreadcrumbsContext'
import { registerLicense } from '@syncfusion/ej2-base'
import {useEffect} from "react"

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlfdnRSQ2dYUUd+XkM=')

const clientSideEmotionCache = createEmotionCache()

const App = props => {
  const router = useRouter()

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false

  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const baseDomain = 'calculate-input.com'
  const langCodes = ['en', 'es', 'pt', 'id']

  const canonicalURL =
    router.locale === 'en'
      ? `https://calculate-input.com${router.asPath}`
      : `https://${router.locale}.calculate-input.com${router.asPath}`

    useEffect(() => {
        const excludeHotjarForSelf = () => {
            const isExcluded = localStorage.getItem('excludeHotjar');

            if (isExcluded === 'true') {
                window.hj = window.hj || function() { (window.hj.q = window.hj.q || []).push(arguments); };
                window.hj('optOut');
                console.log('Hotjar tracking disabled for this user.');
            }
        };

        excludeHotjarForSelf();
    }, []);

    return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link rel='canonical' href={canonicalURL} />
        {langCodes.map(code => (
          <link
            key={code}
            rel='alternate'
            href={`https://${code === 'en' ? '' : `${code}.`}${baseDomain}${router.asPath}`}
            hrefLang={code}
          />
        ))}
        <link rel='alternate' href={`https://${baseDomain}${router.asPath}`} hrefLang='x-default' />
      </Head>

      <GoogleTagManager gtmId='GTM-PCJ2XWX7' />

      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeComponent settings={settings}>
              <BreadcrumbsProvider>{getLayout(<Component {...pageProps} />)}</BreadcrumbsProvider>
              <ReactHotToast>
                <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
              </ReactHotToast>
            </ThemeComponent>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  )
}

export default App

/*
import React from 'react';
import Box from "@mui/material/Box";
import Autocomplete from "../Autocomplete";
import ModeToggler from "../../../@core/layouts/components/shared-components/ModeToggler";
import IconButton from "@mui/material/IconButton";
import {Icon} from "@iconify/react";
import LanguageDropdown from "../../../@core/layouts/components/shared-components/LanguageDropdown";
import {Breadcrumbs, Link, Typography, Divider} from "@mui/material";
import {useBreadcrumbs} from '../../../configs/BreadcrumbsContext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const AppBarContent = ({hidden, settings, saveSettings, toggleNavVisibility}) => {
  const {breadcrumbs} = useBreadcrumbs();

  return (
    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {hidden && (
            <IconButton color='inherit' sx={{ml: -2.75}} onClick={toggleNavVisibility}>
              <Icon icon='mdi:menu'/>
            </IconButton>
          )}
          <Autocomplete hidden={hidden} settings={settings}/>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <LanguageDropdown settings={settings} saveSettings={saveSettings}/>
          <ModeToggler settings={settings} saveSettings={saveSettings}/>
        </Box>
      </Box>
      <Divider variant="middle" sx={{ width: '100%', my: 1 }}/>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small"/>}
        sx={{ width: '100%', mt: 1, mb: 2, justifyContent: 'center' }}
      >
        {breadcrumbs.map((crumb, index) => (
          crumb.url ?
            <Link
              key={index}
              underline="hover"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'primary.main'
              }}
              href={crumb.url}
            >
              <Typography color="inherit" sx={{ mr: 0.5 }}>{crumb.label}</Typography>
            </Link> :
            <Typography
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#625f5f' // Прямое указание цвета для неактивного текста
              }}
            >
              {crumb.label}
            </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default AppBarContent;
*/

import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Autocomplete from '../Autocomplete'
import ModeToggler from '../../../@core/layouts/components/shared-components/ModeToggler'
import IconButton from '@mui/material/IconButton'
import { Icon } from '@iconify/react'
import LanguageDropdown from '../../../@core/layouts/components/shared-components/LanguageDropdown'
import { Breadcrumbs, Divider, Link, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useBreadcrumbs } from '../../../configs/BreadcrumbsContext'

const AppBarContent = ({ hidden, settings, saveSettings, toggleNavVisibility }) => {
  const { breadcrumbs } = useBreadcrumbs()
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const scrollDifference = Math.abs(currentScrollY - lastScrollY)

      if (scrollDifference >= 50) {
        setShowBreadcrumbs(currentScrollY < 50)
        setLastScrollY(currentScrollY)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {hidden && (
            <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
              <Icon icon='mdi:menu' />
            </IconButton>
          )}
          <Autocomplete hidden={hidden} settings={settings} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LanguageDropdown settings={settings} saveSettings={saveSettings} />
          <ModeToggler settings={settings} saveSettings={saveSettings} />
        </Box>
      </Box>
      <Divider variant='middle' sx={{ width: '100%', my: 1 }} />
      {showBreadcrumbs && (
        <Breadcrumbs
          aria-label='breadcrumb'
          separator={<NavigateNextIcon fontSize='small' />}
          sx={{ width: '100%', mt: 1, mb: 2, justifyContent: 'center' }}
        >
          {breadcrumbs.map((crumb, index) =>
            crumb.url ? (
              <Link
                key={index}
                underline='hover'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary.main'
                }}
                href={crumb.url}
              >
                <Typography color='inherit' sx={{ mr: 0.5 }}>
                  {crumb.label}
                </Typography>
              </Link>
            ) : (
              <Typography
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#625f5f'
                }}
              >
                {crumb.label}
              </Typography>
            )
          )}
        </Breadcrumbs>
      )}
    </Box>
  )
}

export default AppBarContent

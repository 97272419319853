import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const BreadcrumbsContext = createContext()

export const useBreadcrumbs = () => useContext(BreadcrumbsContext)

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const router = useRouter()

  const updateBreadcrumbs = useCallback(crumbs => {
    setBreadcrumbs(crumbs)
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      setBreadcrumbs([])
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>
  )
}

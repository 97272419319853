const allowedOrigins = process.env.NEXT_PUBLIC_ALLOWED_ORIGINS.split(',')

async function fetchWithHeaders(url, options = {}) {
  try {
    const origin =
      allowedOrigins.find(o => url.startsWith(o)) || `${process.env.NEXT_PUBLIC_HTTP}${process.env.NEXT_PUBLIC_HOST}`

    const headers = {
      Accept: 'application/json',
      Origin: origin,
      'X-Custom-Header': '111',
      ...options.headers
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}${url}`, { ...options, headers })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response
  } catch (error) {
    console.error('Fetch error:', error.message)
    throw error
  }
}

export default fetchWithHeaders
